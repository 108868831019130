window.NdBalance = class {

    static async showPdfNd(cardNdId)
    {
        FlexLoader.show();
        await fetch(`nd-balance/show-pdf/${cardNdId}`, )
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    return window.open(data.result, '_blank');
                }

                throw new Error(data.error);
            })
            .catch((e) => {
                FlexLoader.hide()
                FlexModal.show({
                    theme: "warning",
                    title: "Aviso",
                    content: e.message
                })
            })
            .finally(() => FlexLoader.hide());
    }
}