window.Recaptcha = class {

    /**
     *
     * @param element form
     * @param string key
     */
    static token(form, key, action)
    {
        FlexLoader.show();
        form.preventDefault();
        let publicKey = key;

        grecaptcha.ready(function() {
            grecaptcha.execute(publicKey, {action: action})
            .then(function(token) {

                if (token === undefined || token == null) {
                    return window.location.href = '/';
                }

                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'recaptcha';
                input.value = token;
                form.target.appendChild(input);
                form.target.submit();
            });
        });
    }
}
