document.addEventListener("DOMContentLoaded", function() {

    let cpf = document.querySelector('.mask-cpf');
    if (cpf !== undefined && cpf !== null) {
        VMasker(cpf).maskPattern('999.999.999-99');
    }

    let cnpj = document.querySelector('.mask-cnpj');
    if (cnpj !== undefined && cnpj !== null) {
        VMasker(cnpj).maskPattern('99.999.999/9999-99');
    }

    let cpf_cnpj = document.querySelector('.mask-cpf-cnpj');
    if (cpf_cnpj !== undefined && cpf_cnpj !== null) {

        cpf_cnpj.addEventListener('focusin', (event) => {
            VMasker(cpf_cnpj).unMask();
        });

        cpf_cnpj.addEventListener('focusout', (event) => {
            if (event.target.value.replace(/[^0-9]/g, '').length < 14) {
                VMasker(cpf_cnpj).maskPattern('999.999.999-99');
            } else {
                VMasker(cpf_cnpj).maskPattern('99.999.999/9999-99');
            }
        })
    }

    let zip = document.querySelector('.mask-zip');
    if (zip !== undefined && zip !== null) {
        VMasker(zip).maskPattern('99999-999');
    }

    let phone = document.querySelector('.mask-phone');
    if (phone !== undefined && phone !== null) {
        VMasker(phone).maskPattern('(99) 9999-9999');
    }

    let cellphone = document.querySelector('.mask-cellphone');
    if (cellphone !== undefined && cellphone !== null) {
        VMasker(cellphone).maskPattern('(99) 99999-9999');
    }

    let birthday = document.querySelector('.mask-birthday');
    if (birthday !== undefined && birthday !== null) {
        VMasker(birthday).maskPattern('99/99/9999');
    }

    let zipCode = document.querySelector('.mask-zip-code');
    if (zipCode !== undefined && zipCode !== null) {
        VMasker(zipCode).maskPattern('99.999-999');
    }

    let maskMoney = document.querySelectorAll('.mask-money');
    if (maskMoney !== undefined && maskMoney !== null) {
        [].forEach.call(maskMoney, (input => VMasker(input).maskMoney()));
    }
});
