window.ViaCep = class
{
    static find(e)
    {
        let value = (e.target !== undefined) ? e.target.value : e;
        if (value === null || value === '') {
            Promise.reject('Cep inválido, verifique.');
            return;
        }
        let onlyNumbers = value.replace(/[^a-z0-9]/gi, '');
        if (onlyNumbers.length < 8) {
            return Promise.reject('Cep inválido, verifique.');
        }

        return fetch(`https://viacep.com.br/ws/${onlyNumbers}/json`)
            .then(response => response.json());
    }

    static findZipCode(e, formId)
    {
        if (e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === "") {
            return;
        }

        FlexLoader.show();
        ViaCep.find(e).then(response => {
            FlexLoader.hide();
            if (response.erro !== undefined) {
                FlexModal.show({
                    theme: 'error',
                    title: 'Alerta',
                    content: 'Cep não encontrado!'
                });
                return;
            }

            let form = document.querySelector(formId);
            form.querySelector('input[name=state]').value = response.uf;
            form.querySelector('input[name=city]').value = response.localidade;
            form.querySelector('input[name=district]').value = response.bairro;
            form.querySelector('input[name=address]').value = response.logradouro;
            form.querySelector('input[name=complement]').value = response.complemento;
            form.querySelector('input[name=address_number]').focus();

        }).catch(error => {
            FlexLoader.hide();
            FlexModal.show({
                theme: 'error',
                title: 'Alerta',
                content: 'Cep não encontrado!'
            });
        });
    }
}