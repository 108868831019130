window.Utils = class {

    /**
     *
     * @param {Array} elements
     * @param {String} className
     */
    static addClassdElement(elements, className)
    {
        for (key in elements) {
            document.querySelector(elements[key]).classList.add(className);
        }
    }

    /**
     *
     * @param {Array} elements
     * @param {String} className
     */
    static removeClassElement(elements, className)
    {
        for (key in elements) {
            document.querySelector(elements[key]).classList.remove(className);
        }
    }

    /**
     *
     * @param {Array} elements
     * @param {String} attribute
     */
    static removeAttributeElement(elements, attribute)
    {
        for (key in elements) {
            document.querySelector(elements[key]).removeAttribute(attribute);
        }
    }

    /**
     *
     * @param {String} value
     * @returns int
     */
    static parseStringToNumber(value)
    {
        return parseFloat(value.replace(",", ""));
    }

    /**
     *
     * @param {Number} value
     * @returns
     */
    static convertNumberToCurrencyBR(value)
    {
        let options = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3 }
        return new Intl.NumberFormat('pt-BR', options).format(value);
    }

    /**
     *
     * @param {DOM} data
     */
    static clearTable(data)
    {
        while (data.hasChildNodes()) {
            data.removeChild(data.lastChild);
        }
    }
}