document.addEventListener("DOMContentLoaded", function() {

    let inputRechargeSelected = document.querySelectorAll(['input[name=recharge_selected]']);
    [].forEach.call(inputRechargeSelected, (item => {
        item.addEventListener('click', () => {
            [].forEach.call(inputRechargeSelected, (item => item.previousSibling.parentNode.classList.remove('active') ))
            item.previousSibling.parentNode.classList.add('active');
        })
    }));

    let inputCardtypeId = document.querySelectorAll(['input[name=card_type_id]']);
    [].forEach.call(inputCardtypeId, (item => {
        item.addEventListener('click', () => {
            [].forEach.call(inputCardtypeId, (item => item.previousSibling.parentNode.classList.remove('active') ))
            item.previousSibling.parentNode.classList.add('active');
        })
    }));

    let typeRechargeSelected = document.querySelectorAll(['input[name=type_recharge_selected]']);
    [].forEach.call(typeRechargeSelected, (item => {
        item.addEventListener('click', () => {
            [].forEach.call(typeRechargeSelected, (item => item.previousSibling.parentNode.classList.remove('active') ))
            item.previousSibling.parentNode.classList.add('active');
        })
    }));
})

window.Recharge = class {

    static autoComplete()
    {
        let form = document.querySelector('#user_form');
        form.querySelector('input[name=document]').value = "590.787.502-00";
        form.querySelector('input[name=name_user]').value = "Joselino pão e vinho";
        form.querySelector('input[name=rg]').value = "";
        form.querySelector('input[name=email]').value = "cleitoncotrin@gmail.com";
        form.querySelector('input[name=cellphone]').value = "(14) 99999-9999";
        form.querySelector('input[name=zip]').value = '17.533-411';
        form.querySelector('input[name=state]').value = 'MG';
        form.querySelector('input[name=city]').value = 'Poçõs de Caldas';
        form.querySelector('input[name=district]').value = 'Vila das Flores';
        form.querySelector('input[name=address]').value = "Rua Tulipa";
        form.querySelector('input[name=address_number]').value = 1000;
        form.querySelector('input[name=complement]').value = "Casa";
        form.querySelector('select[name=country]').value = "BR";
    }

    static setCampaign(campaignId)
    {
        let btnAddUsers = document.querySelector('.btnAddUsers');
        if (campaignId == '') {
            if (btnAddUsers !== null || btnAddUsers !== undefined) {
                btnAddUsers.classList.add('disabled');
            }

            return;
        }

        if (btnAddUsers !== null && btnAddUsers !== undefined) {
            btnAddUsers.classList.remove('disabled');

            let userList = document.querySelector('.userList');
            if (userList !== null && userList !== undefined) {
                userList.classList.remove('hidden');
            }
        }

        FlexLoader.show();
        fetch(`/recharge-line/step-two/set-campaign/${campaignId}`, )
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    return FlexModal.show({
                        theme: "warning",
                        title: "Aviso",
                        content: e.message
                    })
                }

                let uploadLot = document.querySelector('.uploadLot');
                if (uploadLot != undefined || uploadLot != null) {
                    uploadLot.classList.remove('hidden');
                }
            })
            .catch((e) => {
                FlexLoader.hide()
                FlexModal.show({
                    theme: "warning",
                    title: "Aviso",
                    content: e.message
                })
            })
            .finally(() => FlexLoader.hide());
    }

    static showElement(element)
    {
        if (typeof element == 'string') {
            let html = document.querySelector(element);
            return html.classList.remove('hidden')

        }

        element.classList.remove('hidden');
    }

    static hideElement(element)
    {
        if (typeof element == 'string') {
            let html = document.querySelector(element);
            return html.classList.add('hidden')

        }

        element.classList.add('hidden');
    }

    static validatedOptionSelectedStepOne(inputName, elementName)
    {
        let inputs = document.querySelectorAll(`input[name=${inputName}]`);
        [].forEach.call(inputs, (input => {
            if (input.checked) {
                return this.showElement(elementName)
            }
        }))
    }

    static validatedOptionSelectedStepTwo(inputName, elementName)
    {
        let input = document.querySelector(`select[name=${inputName}]`);
        if (input.value != "") {
            return this.showElement(elementName)
        }

        alert('Selecione a campanha para prosseguir!');
    }

    static showModalUploadRecharge()
    {
        let modal = new boostrap.Modal(document.getElementById('uploadRechargeInLot'));
        modal.show();
    }

    static setNd($this)
    {
        Utils.addClassdElement(['.btnShowCreateNd'], 'disabled');
        Utils.addClassdElement(['.createNewNd'], 'hidden');
        Utils.removeClassElement(['.saveStepFour'], 'disabled');
        Utils.removeAttributeElement(['input[name=date_emission]'], 'required');
        let totalRecharges = document.querySelector('input[name=total_recharges]').value;
        let totalNd = $this.dataset.value;
        if (parseFloat(totalNd) < parseFloat(totalRecharges)) {
            this.showModalAlertTotaRechargesGreaterTotalNd(totalRecharges, totalNd)
        }
    }

    static showModalAlertTotaRechargesGreaterTotalNd(totalRecharges, totalNd)
    {
        let total = (totalRecharges - totalNd);
        FlexModal.show({
            noTitle: true,
            maxWidth: '600px',
            showOkToClose: true,
            content: `
                <div class="d-flex flex-column">

                    <h4 class="text-black-100">VALOR DE CARGAS MAIOR QUE SALDO DA NOTA</h4>
                    <span class="mt-2">O valor apontado para cargas é maior que o saldo da nota selecionada para esta operação: </span><br/>

                    <div class="d-flex flex-row flex-wrap justify-content-between">
                        <div class="d-flex flex-column flex-fill bg-light rounded-3 p-4 m-1">
                            <span>Saldo da Nota</span>
                            <span class="text-center fs-3 text-black-100 fw-bold">
                                ${ Utils.convertNumberToCurrencyBR(totalNd) }
                            </span>
                        </div>
                        <div class="d-flex flex-column flex-fill bg-light rounded-3 p-4 m-1">
                            <span>Valor de Cargas</span>
                            <span class="text-center fs-3 text-black-100 fw-bold">
                                ${ Utils.convertNumberToCurrencyBR(totalRecharges) }
                            </span>
                        </div>
                    </div>

                    <div class="d-flex flex-row flex-wrap justify-content-between">
                        <div class="d-flex flex-column flex-fill bg-light rounded-3 p-4 m-1">
                            <span>Saldo da Nota</span>
                            <span class="text-center fs-3 text-danger fw-bold">
                                ${ Utils.convertNumberToCurrencyBR(total) }
                            </span>
                        </div>
                    </div>

                    <div class="mt-4">
                        Você permite que sejam utilizadas outras notas com saldo para cobrir a <br/>
                        diferença ou realizar um novo faturamento, caso necessário? O time da <br/>
                        Incenticard avaliará qual opção será necessária.
                    </span>

                    <div class="d-flex flex-row flex-wrap align-items-center justify-content-center mt-4 mb-4">
                        <span class="flex-grow-1">
                            <button
                                class="btn btn-default w-100 text-black-50" onclick="location.reload(true)">
                                Cancelar
                            </button>
                        </span>
                        <span class="flex-grow-1">
                            <button
                                class="btn btn-primary text-white fw-bold w-100" onclick="FlexModal.hide()">
                                Permitir
                            </button>
                        </span>
                    </div>
                </div>
            `,
        });
    }

    static searchBearerByParam(filter, cardTypeId)
    {
        if (filter.value == "") return;

        FlexLoader.show();
        let form = new FormData();
        form.append('_token', document.querySelector('input[name=_token]').value);
        form.append('filter', filter.value);
        form.append('card_type_id', cardTypeId);
        fetch(`/card-bearer/step-two/search-bearer`, { method: 'POST', body: form })
            .then((response) => response.json())
            .then(result => {
                if (!result.status) {
                    FlexModal.show({
                        theme: "warning",
                        title: "Aviso",
                        content: result.error
                    })

                    return;
                }

                let { data } = result;
                this.filterUserTableRecharge(data);
            })
            .finally(() => FlexLoader.hide());
    }

    static filterUserTableRecharge(data)
    {
        let tbody = document.querySelector('#tableFilterUsersToRecharge');
        Utils.clearTable(tbody)
        tbody.innerHTML = "";
        for (const key in data) {
            let content = this.mountContentTableFilterUsersToRecharge(data[key])
            tbody.insertAdjacentHTML('afterbegin' , content);
        }
    }

    static mountContentTableFilterUsersToRecharge(data)
    {
        let { id, cpf, name, proxy, card_id } = data;
        let item = JSON.stringify(data);
        let content =`
            <tr id='filter_users_${card_id}'>
                <td class="align-middle">
                    <small><b>CPF: </b></small>${cpf} <br/>
                    <small><b>PROXY: </b></small>${proxy} <br/>
                    <small><b>NOME: </b></small>${name} <br/>
                </td>
                <td class='text-end align-middle'>
                    <button
                        onclick='Recharge.addUserToRecharge(${item})'
                        class='btn btn-sm btn-success text-white'>
                        <i class='bi bi-plus fs-6'></i>
                    </button>
                </td>
                <td></td>
            </tr>
        `;

        return content;
    }

    static addUserToRecharge(data)
    {
        let tbody = document.querySelector('#tableAddUsersRecharge');
        let count = tbody.rows.length;
        if (count >= 20) {
            FlexModal.show({
                theme: "warning",
                title: "Atenção",
                content: "Limite de recargas atingidas, limite maximo: 20"
            });

            return;
        }

        let content = this.mountContentTableAddUsersToRecharge(data);
        tbody.insertAdjacentHTML('afterbegin' , content);
        this.hideElement(`#filter_users_${data.card_id}`);
        this.setMaskRechargeValue();
        Utils.removeClassElement(['#addUserToRecharge'], 'disabled');
        count ++;
        document.querySelector('.totalRecharge').textContent = `Total: ${count}`
    }

    static mountContentTableAddUsersToRecharge(data)
    {
        let { id, cpf, name, card_id, proxy } = data;
        let content =`
            <tr id='recharge_${card_id}'>
                <td class="hidden cardBearerId">${id}</td>
                <td class="hidden proxy">${proxy}</td>
                <td class="align-middle">
                    ${cpf} <br/>
                    ${proxy}
                </td>
                <td class="align-middle">${name}</td>
                <td class="align-middle">
                    <input type='text' class='form-control form-control-sm mask-money' value="0,00"/>
                </td>
                <td class='text-end align-middle'>
                    <button
                        onclick='Recharge.removeUserToRecharge("${card_id}")'
                        class='btn btn-sm btn-danger text-white'>
                        <i class='bi bi-x fs-6'></i>
                    </button>
                </td>
            </tr>
        `;

        return content;
    }

    static removeUserToRecharge(card_id)
    {
        document.querySelector(`#recharge_${card_id}`).remove();
        this.showElement(`#filter_users_${card_id}`);
        const tbody = document.querySelector('#tableAddUsersRecharge');
        let count = tbody.rows.length;
        document.querySelector('.totalRecharge').textContent = `Total: ${count}`
        if (tbody.rows.length <= 0) {
            Utils.addClassdElement(['#addUserToRecharge'], 'disabled');
        }
    }

    static setMaskRechargeValue()
    {
        let maskMoney = document.querySelectorAll('.mask-money');
        if (maskMoney !== undefined && maskMoney !== null) {
            [].forEach.call(maskMoney, (input => VMasker(input).maskMoney()));
        }
    }

    static addUser(rechargeId)
    {
        if (!this.validateAddUser()) {
            return alert('Existem valores inválidos, verifique!');
        }

        FlexLoader.show();
        fetch(`/recharge-line/step-two/add-user-with-ajax`, { method: 'POST', body: this.mountBodyAddUser(rechargeId) })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    return window.location.href = `/recharges/step-four/${rechargeId}`;
                }

                FlexModal.show({
                    theme: "error",
                    title: "Tivemos um problema!",
                    content: data.error
                });
            })
            .finally(() => {
                FlexLoader.hide();
            });
    }

    static mountBodyAddUser(rechargeId)
    {
        const recharges = [];
        const tbody = document.querySelector('#tableAddUsersRecharge');
        const count = tbody.rows.length;
        for (let i = 0; i < count; i++) {
            let recharge = {};
            recharge.recharge_id = rechargeId;
            recharge.bearer_id = tbody.rows[i].cells[0].textContent.trim();
            recharge.proxy = tbody.rows[i].cells[1].textContent.trim();
            let input = tbody.rows[i].querySelector('input');
            if (input !== null) {
                recharge.value = input.value;
            }
            recharges.push(recharge);
        }

        const form = new FormData();
        form.append('_token', document.querySelector('input[name=_token]').value);
        form.append('recharges', JSON.stringify(recharges));

        return form;
    }

    static validateAddUser()
    {
        const rows = document.querySelectorAll('#tableAddUsersRecharge tr td');
        let valid = true;
        [].forEach.call(rows, (row => {
            let input = row.querySelector('input');
            if (input !== null) {
                if (parseFloat(input.value) <= 0) {
                    input.style.border = '1px solid #dc3545';
                    valid = false;
                }
            }
        }));

        return valid;
    }

    static removeUser(userId)
    {
        FlexLoader.show();
        fetch(`/recharge-line/delete/${userId}`, )
            .then((response) => response.json())
            .then(data => {
                if (!data.status) {
                    return FlexModal.show({
                        theme: "warning",
                        title: "Aviso",
                        content: e.message
                    })
                }

                return window.location.reload();
            })
            .catch((e) => {
                FlexLoader.hide()
                FlexModal.show({
                    theme: "warning",
                    title: "Aviso",
                    content: e.message
                })
            })
            .finally(() => FlexLoader.hide());
    }
}