import Chart from 'chart.js/auto';
import { assignIn } from 'lodash';

window.Dashboard = class {

    static search()
    {
        this.token = document.querySelector('input[name=_token]').value;
        Promise.all([
            this.getTotalNds(),
            this.getTotalRecharges(),
            this.getTotalCardActives(),
            this.getTotalBalanceAvailableNd(),
            this.getNdRecharges()
        ])
        .then(() => this.calculePercentage());
    }

    static filter(e)
    {
        e.preventDefault();

        let _form = document.querySelector('#form-dashboard');
        this.cardNdId = _form.querySelector('select[name=nd]').value;
        this.cardCampaignId = _form.querySelector('select[name=campaigns]').value;
        this.search();
    }

    static async getNdRecharges()
    {
        if (this.myChart !== undefined) return;

        await fetch('dashboard/nd-recharges')
            .then((response) => response.json())
            .then(data => {

                if (data.status) {

                    this.chartLabels = data.result.months;
                    this.chartData = data.result.values;

                    return this.buildDashboard();
                }

                throw new Error(data.error);
            })
            .catch((e) => {})
            .finally(() => {});
    }

    static async getTotalNds()
    {
        let form = new FormData();
        form.append('_token', this.token);

        if (this.cardNdId !== undefined && this.cardNdId !== null) {
            form.append('card_nd_id', this.cardNdId);
        }

        this.hideElement('.total-nd-spinner', false)
        this.hideElement('.total-nd', true);

        await fetch('dashboard/total-nds', { method: 'POST', body: form })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    this.totalNds = data.result.total_nds;

                    return this.setValueElement('.total-nd', data.result.total_nds_formatted);
                }

                throw new Error(data.error);
            })
            .catch((e) => this.setValueElement('.total-nd', '0.00'))
            .finally(() => {
                this.hideElement('.total-nd', false);
                this.hideElement('.total-nd-spinner', true);
            });
    }

    static async getTotalRecharges()
    {
        let form = new FormData();
        form.append('_token', this.token);

        if (this.cardCampaignId !== undefined && this.cardCampaignId !== null) {
            form.append('card_campaign_id', this.cardCampaignId);
        }

        if (this.cardNdId !== undefined && this.cardNdId !== null) {
            form.append('card_nd_id', this.cardNdId);
        }

        this.hideElement('.total-recharge-spinner', false)
        this.hideElement('.total-recharge', true);

        await fetch('dashboard/total-recharges', { method: 'POST', body: form })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    this.totalRecharges = data.result.total_recharges;

                    return this.setValueElement('.total-recharge', data.result.total_recharges_formatted);
                }

                throw new Error(data.error);
            })
            .catch((e) => this.setValueElement('.total-recharge', '0.00'))
            .finally(() => {
                this.hideElement('.total-recharge', false);
                this.hideElement('.total-recharge-spinner', true);
            });
    }

    static calculePercentage()
    {
        this.hideElement('.percent-nd-recharge-spinner', false)
        this.hideElement('.percent-nd-recharge', true);
        setTimeout(() => {
            let percentage = 0;

            if (this.totalRecharges !== 0 && this.totalNds !== 0) {
                percentage = Math.round((this.totalRecharges * 100) / this.totalNds);
            }

            this.setValueElement('.percent-nd-recharge', `${percentage}%`);
            this.hideElement('.percent-nd-recharge-spinner', true);
            this.hideElement('.percent-nd-recharge', false);
        },500);
    }

    static async getTotalCardActives()
    {
        let form = new FormData();
        form.append('_token', this.token);

        if (this.cardCampaignId !== undefined && this.cardCampaignId !== null) {
            form.append('card_campaign_id', this.cardCampaignId);
        }

        this.hideElement('.total-cards-actives-spinner', false)
        this.hideElement('.total-cards-actives', true);

        await fetch('dashboard/total-cards-actives', { method: 'POST', body: form })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    return this.setValueElement('.total-cards-actives', data.result.total_card_actives);
                }

                throw new Error(data.error);
            })
            .catch((e) => this.setValueElement('.total-cards-actives', '0'))
            .finally(() => {
                this.hideElement('.total-cards-actives', false);
                this.hideElement('.total-cards-actives-spinner', true);
            });
    }

    static async getTotalBalanceAvailableNd()
    {
        let form = new FormData();
        form.append('_token', this.token);

        if (this.cardNdId !== undefined && this.cardNdId !== null) {
            form.append('card_nd_id', this.cardNdId);
        }

        this.hideElement('.total-balance-spinner', false)
        this.hideElement('.total-balance', true);

        await fetch('dashboard/total-balance-available', { method: 'POST', body: form })
            .then((response) => response.json())
            .then(data => {
                if (data.status) {
                    return this.setValueElement('.total-balance', data.result.total_balance_available_formatted);
                }

                throw new Error(data.error);
            })
            .catch((e) => this.setValueElement('.total-balance', '0.00'))
            .finally(() => {
                this.hideElement('.total-balance', false);
                this.hideElement('.total-balance-spinner', true);
            });
    }

    static hideElement(element, hide)
    {
        if (hide) {
            return document.querySelector(element).classList.add('d-none');
        }

        return document.querySelector(element).classList.remove('d-none');
    }

    static setValueElement(element, value)
    {
        document.querySelector(element).textContent = value;
    }

    /**
     * Daqui para baixo são funções que alimentam o dashboard
     */
    static buildDashboard()
    {
        this.buildChartData();
        this.buildOptionsData();
        this.instance();
    }

    static instance()
    {
        const ctx = document.getElementById('dashNfs');

        this.myChart = new Chart(ctx, {
            type: (this.typeChart !== undefined) ? this.typeChart : 'line',
            data: this.chartData,
            options: this.optionsData
        });
    }


    static buildChartData()
    {
        this.chartData = {
            labels: this.chartLabels,
            datasets: [
                {
                    label: '',
                    data: this.chartData,
                    backgroundColor: [
                        'rgba(232, 164, 74, 1)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 205, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(201, 203, 207, 0.2)'
                    ],
                    borderColor: [
                        'rgba(232, 164, 74, 1)',
                        'rgb(255, 159, 64)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)',
                        'rgb(201, 203, 207)'
                    ],
                    borderWidth: 2
                }
            ]
        }
    }

    static buildOptionsData()
    {
        this.optionsData = {
            plugins: this.buildPlugins(),
            scales: this.buildScales()
        }
    }

    static buildPlugins()
    {
        const plugins = {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }

                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(context.parsed.y);
                        }
                        return label;
                    }
                }
            },
            title: {
                display: true,
                text: "VALOR DE RECARGA x PERÍODO",
                align: 'start',
                font: {
                    size: 24,
                    weight: 'bold'
                }
            }
        }

        return plugins;
    }

    static buildScales()
    {
        const scales = {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                       return new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(value);
                    }
                }
            }
        }

        return scales;
    }

    static setTypeChart(event)
    {
        this.typeChart = event.target.value;
        this.rebuildChart();
    }

    static rebuildChart()
    {
        this.myChart.destroy();
        this.instance();
        this.addOrRemoveOptions();
        this.myChart.update();
    }

    static addOrRemoveOptions()
    {
        const types = ['polarArea', 'radar', 'pie'];

        if (types.includes(this.typeChart)) {
            this.myChart.options.plugins = {};
            this.myChart.options.scales = {};
        } else {
            this.myChart.options.plugins = this.buildPlugins()
            this.myChart.options.scales = this.buildScales();
        }
    }
}